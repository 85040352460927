import React from 'react';
import styled from 'styled-components';

const BaseHeader = styled.span`
  color: ${props => props.theme.colors.black};
  font-family: ${props => props.theme.fonts.text}, sans-serif;
  line-height: 1.2;
`;

export const H1 = styled(BaseHeader)`
  font-size: 2.074em;
  text-align: ${props => !!props.center ? 'center' : 'left'};
`;

export const H2 = styled(BaseHeader)`
  font-size: 1.728em;
`;

export const H3 = styled(BaseHeader)`
  font-size: 1.44em;
`;

export const H4 = styled(BaseHeader)`
  font-size: 1.2em;
`;

const Headings = {
  H1: H1,
  H2: H2,
  H3: H3,
  H4: H4,
}

const Heading = ({ children, size, center }) => {
  const SpecificHeading = Headings[`H${(!size ? 1 : size)}`];

  return <SpecificHeading as={`h${!size ? 1 : size}`} center={center}>{children}</SpecificHeading>;
}

export default Heading;
